html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: rgb(240, 242, 245);
}

a {
  cursor: pointer !important;
  text-decoration: none !important;
}

td p {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .hidden-sm {
    display: none !important;
  }
  .ant-card-extra {
    display: none !important;
  }
  .ant-layout-sider-zero-width-trigger {
    right: -8px !important;
  }
}

@media (max-width: 700px) {
  .hidden-patty {
    display: none !important;
  }
}

.up-antd-table-row-selected {
  background-color: #ececec;
}

.ant-menu-item-group-title {
  font-style: italic;
}

.ant-card-extra .ant-tabs-bar {
  margin: 0;
  border-bottom: none !important;
}

.ant-collapse-header-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: "100%";
}

.tabs-in-card-extra .ant-card-extra {
  padding: 8.25px 0 !important;
}

.ant-table-expanded-row .ant-table * {
  font-size: 98% !important;
}

.ant-table-expanded-row th,
.ant-table-expanded-row .ant-table-footer {
  background-color: transparent !important;
}

.ant-table-expanded-row em {
  color: #42a5f5;
}

.custom-radio .ant-radio-inner {
  width: 20px;
  height: 20px;
}

.custom-radio-button .ant-radio-inner {
  border-color: #1890ff;
}

.custom-selected-row .ant-checkbox-inner {
  border-color: #1890ff;
}

.custom-table .ant-table-row, 
.custom-table .ant-table-cell {
  vertical-align: top !important;
}

/* match dark mode side nav */
.ant-drawer-content {
  background-color: #000c17 !important;
}

/* For the antd Upload component */
.ant-upload.ant-upload-select {
    background-color: rgba(0, 0, 0, 0.02);
    border-color: rgb(217, 217, 217);
    border: dashed;
    border-width: 1px;
    cursor: pointer;
    border-radius: 8px;
    padding: 16px;
    margin: 16px;
    border-color: initial;
    transition: border-color 0.1s ease;
}
.ant-upload.ant-upload-select:hover {
    border-color: rgb(22, 119, 255);
}
.ant-upload-list.ant-upload-list-text {
    margin: 0 8px 16px 8px;
}

/* page progress bar */
@keyframes line {
  from {
    left: 0%;
    width: 25%;
  }
  to {
    left: 75%;
    width: 100%;
  }
}

.page-progress-bar {
  z-index: 99 !important;
  position: absolute;
  left:0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, rgb(24, 144, 255, 0), rgb(24, 144, 255), rgb(24, 144, 255, 0));
  animation: line 1s ease infinite;
}